import React, {useEffect, useState} from 'react';

const CustomSVG = ({svgContent, color = "#000", width = 80, height = 80}) => {
    const [svgData, setSvgData] = useState('');

    useEffect(() => {
        const fetchSVG = async () => {
            const response = await fetch(svgContent);
            const text = await response.text();
            setSvgData(text);
        }
        fetchSVG();
    }, []);

    const extractSVGContent = (svgString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgString, 'image/svg+xml');
        const paths = doc.querySelectorAll('path');

        return Array.from(paths).map(path => ({
            d: path.getAttribute('d'), // Path data
            id: path.getAttribute('id'), // Path id (if exists)
        }));
    };

    const getViewBox = (svgString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgString, 'image/svg+xml');
        const svgElement = doc.querySelector('svg');

        return svgElement?.getAttribute('viewBox')
    };

    const paths = extractSVGContent(svgData);
    const viewBox = getViewBox(svgData);

    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width={`${width}pt`}
            height={`${height}pt`}
            viewBox={viewBox}
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform={paths.length > 1 ? "translate(0.000000,128.000000) scale(0.100000,-0.100000)" : 'none'}
                fill={color}
                stroke="none"
            >
                {paths.map((data, index) => (
                    <path key={index} d={data.d} id={data.id}/>
                ))}
            </g>
        </svg>
    );
};

export default CustomSVG;