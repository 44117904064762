import React, {lazy} from 'react';
import permissions from "./permissions";
import AuthGuard from "gaurds/AuthGuard";
import GuestGuard from "gaurds/GuestGuard";
import AdminLayout from "layouts/AdminLayout";
import {Route} from "react-router-dom";
import PermissionGuard from "../gaurds/PermissionGuard";

const Login = lazy(() => import("../pages/login/Login"))

const Home = lazy(() => import("../pages/home/Home"))

const InboundOrders = lazy(() => import("../pages/orders/pages/InboundOrder/pages"))
const InboundOrdersReceiveByOrder = lazy(() => import("../pages/orders/pages/InboundOrder/pages/receiveByOrder"))
const InboundOrdersAdhoc = lazy(() => import("../pages/orders/pages/InboundOrder/pages/adhoc"))
const InboundOrdersAddToStock = lazy(() => import("../pages/orders/pages/InboundOrder/pages/addToStock"))
const InboundOrdersComplyReceive = lazy(() => import("../pages/orders/pages/InboundOrder/pages/complyReceive"))
const InboundOrdersBlindReceive = lazy(() => import("../pages/orders/pages/InboundOrder/pages/blindReceive"))

const AddInboundOrder = lazy(() => import("../pages/orders/pages/InboundOrder/pages/receiveByOrder/add"))
const EditInboundOrder = lazy(() => import("../pages/orders/pages/InboundOrder/pages/receiveByOrder/edit"))
const SplitInboundOrder = lazy(() => import("../pages/orders/pages/InboundOrder/pages/receiveByOrder/split"))
const ScanReceiveByOrderInboundOrder = lazy(() => import("../pages/orders/pages/InboundOrder/pages/receiveByOrder/scan"))

const ScanInboundAdhoc = lazy(() => import("../pages/orders/pages/InboundOrder/pages/adhoc/scan"))
const AdhocWithoutSupplier = lazy(() => import("../pages/orders/pages/InboundOrder/pages/adhoc/withoutSupplier"))

const AddComplyReceive = lazy(() => import("../pages/orders/pages/InboundOrder/pages/complyReceive/addComplyReceive"))

const ScanBlindReceive = lazy(() => import("../pages/orders/pages/InboundOrder/pages/blindReceive/scan"))
const AggregationBlindReceive = lazy(() => import("../pages/orders/pages/InboundOrder/pages/blindReceive/aggregation"))

const OutboundOrders = lazy(() => import("../pages/orders/pages/OutboundOrder/pages"))
const OutboundOrdersShipOutByOrder = lazy(() => import("../pages/orders/pages/OutboundOrder/pages/shipOutOrders"))
const OutboundOrdersAdhoc = lazy(() => import("../pages/orders/pages/OutboundOrder/pages/adhocShipOut"))
const OutboundOrdersCancelShipping = lazy(() => import("../pages/orders/pages/OutboundOrder/pages/cancelShipout"))

const AddOutboundOrder = lazy(() => import("../pages/orders/pages/OutboundOrder/pages/shipOutOrders/add"))
const EditOutboundOrder = lazy(() => import("../pages/orders/pages/OutboundOrder/pages/shipOutOrders/edit"))
const ScanShipOutOrderOutboundOrder = lazy(() => import("../pages/orders/pages/OutboundOrder/pages/shipOutOrders/scan"))
const AddCancelOrder = lazy(() => import("../pages/orders/pages/OutboundOrder/pages/cancelShipout/addCancelOrder"))

const ScanOutboundAdhoc = lazy(() => import("../pages/orders/pages/OutboundOrder/pages/adhocShipOut/scan"))

const Serialization = lazy(() => import("../pages/plantMaster/Serialization"))
const AddSerializationOrder = lazy(() => import("../pages/plantMaster/AddSerializationOrder"))
const EditSerializationOrder = lazy(() => import("../pages/plantMaster/EditSerializationOrder"))

const MoveOrders = lazy(() => import("../pages/moveOrders/MoveOrders"))
const AddMoveOrder = lazy(() => import("pages/moveOrders/AddMoveOrder"))

const Files = lazy(() => import("../pages/files/Files"))
const AddFile = lazy(() => import("../pages/files/AddFile"))

const Machines = lazy(() => import("../pages/machines/Machines"))
const AddMachine = lazy(() => import("../pages/machines/AddMachine"))
const EditMachine = lazy(() => import("../pages/machines/EditMachine"))

const Partners = lazy(() => import("../pages/partners/Partners"))

const AddPartner = lazy(() => import("../pages/partners/AddPartner"))
const AddPartnerSingle = lazy(() => import("../pages/partners/SingleEntryFields.js"))
const AddPartnerMultiple = lazy(() => import("../pages/partners/MultipleEntryFields.js"))

const EditPartner = lazy(() => import("../pages/partners/EditPartner"))

const Products = lazy(() => import("../pages/products/Products"))

const AddProduct = lazy(() => import("../pages/products/AddProduct"))
const AddSingleProduct = lazy(() => import("../pages/products/SingleEntryFields.js"))
const AddMultipleProduct = lazy(() => import("../pages/products/MultipleEntryFields.js"))

const EditProduct = lazy(() => import("../pages/products/EditProduct"))

const SKUs = lazy(() => import("../pages/skus/SKUs"))

const AddSKU = lazy(() => import("../pages/skus/AddSKU"))
const AddSKUSingle = lazy(() => import("../pages/skus/SingleEntryFields.js"))
const AddSKUMultiple = lazy(() => import("../pages/skus/MultipleEntryFields.js"))

const Mapping = lazy(() => import("../pages/inventory/Mapping"))

const AddMapping = lazy(() => import("../pages/inventory/AddMapping"))
const AddMappingSingle = lazy(() => import("../pages/inventory/SingleEntryFields.js"))
const AddMappingMultiple = lazy(() => import("../pages/inventory/MultipleEntryFields.js"))

const AvailabilityReport = lazy(() => import("../pages/reports/AvailabilityReport"))
const AuditTrailReport = lazy(() => import("../pages/reports/history/AuditTrailReport"))
const SupplierReport = lazy(() => import("../pages/reports/SupplierReport"))
const QuantityReport = lazy(() => import("../pages/reports/QuantityReport"))
const DecantingReports = lazy(() => import("../pages/reports/DecantingReports"))
const BatchReport = lazy(() => import("../pages/reports/BatchReport"))
const BatchStatus = lazy(() => import("../pages/reports/BatchStatus"))
const NonSerializedStatus = lazy(() => import("../pages/reports/NonSerializedStatus"))

const Roles = lazy(() => import("../pages/roles/Roles"))
const AddRole = lazy(() => import("../pages/roles/AddRole"))
const EditRole = lazy(() => import("../pages/roles/EditRole"))
const DuplicateRole = lazy(() => import("../pages/roles/DuplicateRole"))

const Users = lazy(() => import("../pages/users/Users"))
const AddUser = lazy(() => import("../pages/users/AddUser"))
const EditUser = lazy(() => import("../pages/users/EditUser"))
const PointOfSale = lazy(() => import("../pages/pointOfSale/PointOfSale"))

const VerifyStatus = lazy(() => import("../pages/verifyStatus/VerifyStatus"))
const Verification = lazy(() => import("../pages/verification/Verification"))
const Decommission = lazy(() => import("../pages/decommission/Decommission"))
const Activate = lazy(() => import("../pages/activate/Activate"))

const Pack = lazy(() => import("../pages/pack/Pack"))
const PackAggregation = lazy(() => import("../pages/pack/components/ByAggregation.js"))
const PackOrders = lazy(() => import("../pages/pack/components/ByOrder.js"))
const PackAdd = lazy(() => import("../pages/pack/components/ByAdd.js"))
const PackRemove = lazy(() => import("../pages/pack/components/ByRemove.js"))
const PackReprint = lazy(() => import("../pages/pack/components/Reprint.js"))

const PackScanOutbound = lazy(() => import("../pages/pack/components/byOrder/PackScanOutbound"))

const Unpack = lazy(() => import("../pages/unpack/Unpack"))
const UnpackParent = lazy(() => import("../pages/unpack/components/UnpackByParent.js"))
const UnpackChild = lazy(() => import("../pages/unpack/components/UnpackByChild.js"))

const PermitNumber = lazy(() => import("../pages/permitNumber"))
const AddPermitNumber = lazy(() => import("../pages/permitNumber/addEdit"))
const EditPermitNumber = lazy(() => import("../pages/permitNumber/addEdit"))

const SerialGenerator = lazy(() => import("../pages/serialGenerator/SerialGenerator"))

const CycleCount = lazy(() => import("../pages/cycleCount/CycleCount"))

const CreateCycleCount = lazy(() => import("../pages/cycleCount/CreateCycleCount"))

const UpdateCycleCount = lazy(() => import("../pages/cycleCount/UpdateCycleCount"))

const ScanCycleCount = lazy(() => import("../pages/cycleCount/ScanCycleCount"))

const ReportCycleCount = lazy(() => import('../pages/cycleCount/ReportCycleCount'));

const AdhocCycleCount = lazy(() => import('../pages/cycleCount/AdhocCycleCount'));

const AdminPanel = lazy(() => import('../pages/admin-panel/AdminPanel'));

const TenantSettings = lazy(() => import('pages/admin-panel/TenantSettings/index.js'));


const ResetPassword = lazy(() => import("../pages/reset-password/ResetPassword"))

const Error = lazy(() => import("../pages/error/Error"))

const routes = () => {
    return (
        <>
            <Route path='/' element={<GuestGuard><Login/></GuestGuard>}/>

            <Route path='/home' element={<AuthGuard><AdminLayout><Home/></AdminLayout></AuthGuard>}/>

            <Route path='/inbound' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundView]}><InboundOrders/></PermissionGuard></AdminLayout></AuthGuard>}>

                <Route path='receive-by-order' element={<InboundOrdersReceiveByOrder/>}/>

                <Route path='adhoc' element={<InboundOrdersAdhoc/>}/>

                <Route path='add-to-stock' element={<InboundOrdersAddToStock/>}/>

                <Route path='comply-receive' element={<InboundOrdersComplyReceive/>}/>

                <Route path='blind-receive' element={<InboundOrdersBlindReceive/>}/>
            </Route>
            <Route path='/inbound/receive-by-order/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundCreateOrder]}><AddInboundOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/inbound/receive-by-order/:id/edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundUpdateOrder]}><EditInboundOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/inbound/receive-by-order/:id/split' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundUpdateOrder]}><SplitInboundOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/inbound/receive-by-order/:id/scan' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundReleaseToQuality, permissions.InboundAccept, permissions.InboundReject]}><ScanReceiveByOrderInboundOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>

            <Route path='/inbound/adhoc/:id/scan' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundAccept]}><ScanInboundAdhoc/></PermissionGuard></AdminLayout></AuthGuard>}/>

            <Route path='/inbound/adhoc/without-supplier' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundAccept]}><AdhocWithoutSupplier/></PermissionGuard></AdminLayout></AuthGuard>}/>

            <Route path='/inbound/comply-receive/add-comply-receive' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundAccept]}><AddComplyReceive/></PermissionGuard></AdminLayout></AuthGuard>}/>

            <Route path='/inbound/blind-receive/:id/scan' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundAccept]}><ScanBlindReceive/></PermissionGuard></AdminLayout></AuthGuard>}/>

            <Route path='/inbound/blind-receive/:id/add-aggregation' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundAccept]}><AggregationBlindReceive/></PermissionGuard></AdminLayout></AuthGuard>}/>

            <Route path='/outbound' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.OutboundView]}><OutboundOrders/></PermissionGuard></AdminLayout></AuthGuard>}>

                <Route path='shipout-by-order' element={<OutboundOrdersShipOutByOrder/>}/>

                <Route path='adhoc' element={<OutboundOrdersAdhoc/>}/>

                <Route path='cancel-shipping' element={<OutboundOrdersCancelShipping/>}/>
            </Route>

            <Route path='/outbound/shipout-by-order/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.OutboundCreateOrder]}><AddOutboundOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/outbound/shipout-by-order/:id/edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.OutboundUpdateOrder]}><EditOutboundOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/outbound/shipout-by-order/:id/scan' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.OutboundCreateOrder]}><ScanShipOutOrderOutboundOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>

            <Route path='/outbound/adhoc/:id/scan' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.OutboundCreateOrder]}><ScanOutboundAdhoc/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/outbound/cancel-shipping/add-cancel-order' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundAccept]}><AddCancelOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/serialization' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.SO_View]}><Serialization/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/serialization/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.SO_Add]}><AddSerializationOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/serialization/:id/Edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.SO_Edit]}><EditSerializationOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/move-orders' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.MO_View]}><MoveOrders/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/move-orders/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.MO_Add]}><AddMoveOrder/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/files' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundFileView]}><Files/></PermissionGuard></AdminLayout></AuthGuard>}/>

            <Route path='/files/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.InboundCreateInboundFile]}><AddFile/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/machines' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Machine_View]}><Machines/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/machines/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Machine_Add]}><AddMachine/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/machines/:id/Edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Machine_Edit]}><EditMachine/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/partners' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.PartnerView]}><Partners/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/partners/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.PartnerCreate]}><AddPartner/></PermissionGuard></AdminLayout></AuthGuard>}>
                <Route path='single' element={<AddPartnerSingle/>}/>
                <Route path='multiple' element={<AddPartnerMultiple/>}/>
            </Route>


            <Route path='/partners/:id/edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.PartnerCreate]}><EditPartner/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/products' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ProductView]}><Products/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/products/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ProductCreate]}><AddProduct/></PermissionGuard></AdminLayout></AuthGuard>}>
                <Route path='single' element={<AddSingleProduct/>}/>
                <Route path='multiple' element={<AddMultipleProduct/>}/>
            </Route>


            <Route path='/products/:id/edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ProductCreate]}><EditProduct/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/skus' element={<AuthGuard><AdminLayout><SKUs/></AdminLayout></AuthGuard>}/>

            <Route path='/skus/add' element={<AuthGuard><AdminLayout><AddSKU/></AdminLayout></AuthGuard>}>
                <Route path='single' element={<AddSKUSingle/>}/>
                <Route path='multiple' element={<AddSKUMultiple/>}/>
            </Route>


            <Route path='/mapping' element={<AuthGuard><AdminLayout><Mapping/></AdminLayout></AuthGuard>}/>

            <Route path='/mapping/add' element={<AuthGuard><AdminLayout><AddMapping/></AdminLayout></AuthGuard>}>
                <Route path='single' element={<AddMappingSingle/>}/>
                <Route path='multiple' element={<AddMappingMultiple/>}/>
            </Route>


            <Route path='/availability-report' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ViewProductAvailabilityReport]}><AvailabilityReport/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/auditTrail-report' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ViewProductAvailabilityReport]}><AuditTrailReport/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/supplier-report' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ViewProductBySupplierReport]}><SupplierReport/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/quantity-report' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ViewProductQuantityReport]}><QuantityReport/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/decanting-report' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ViewProductQuantityReport]}><DecantingReports/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/batch-availability' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ViewProductQuantityReport]}><BatchReport/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/batch-status' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ViewProductQuantityReport]}><BatchStatus/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/non-serialized' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ViewProductQuantityReport]}><NonSerializedStatus/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/roles' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.UserManagement]}><Roles/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/roles/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.UserManagement]}><AddRole/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/roles/:id/edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.UserManagement]}><EditRole/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/roles/:id/duplicate' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.UserManagement]}><DuplicateRole/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/users' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.UserManagement]}><Users/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/users/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.UserManagement]}><AddUser/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/users/:id/edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.UserManagement]}><EditUser/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/point-of-sale' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.POS_Sell]}><PointOfSale/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/verify-status' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Verify_Status]}><VerifyStatus/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/verification' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.ItemVerification]}><Verification/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/decommission' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.DecomissionView]}><Decommission/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/activate' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Activate]}><Activate/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/pack' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><Pack/></PermissionGuard></AdminLayout></AuthGuard>}>
                <Route path='aggregation' element={<PackAggregation/>}/>
                <Route path='orders' element={<PackOrders/>}/>
                <Route path='add' element={<PackAdd/>}/>
                <Route path='remove' element={<PackRemove/>}/>
                <Route path='reprint' element={<PackReprint/>}/>
            </Route>


            <Route path='/pack/orders/:id/order_pack' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><PackScanOutbound/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/unpack' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><Unpack/></PermissionGuard></AdminLayout></AuthGuard>}>
                <Route path='parent' element={<UnpackParent/>}/>
                <Route path='child' element={<UnpackChild/>}/>
            </Route>


            <Route path='/permit-number' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><PermitNumber/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/permit-number/add' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><AddPermitNumber/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/permit-number/:id/edit' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><EditPermitNumber/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/serial-generator' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><SerialGenerator/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/cycle-count' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.CycleCount_View]}><CycleCount/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/cycle-count/create' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.CycleCount_Create]}><CreateCycleCount/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/cycle-count/:id/Update' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.CycleCount_Create]}><UpdateCycleCount/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/cycle-count/:id/Scan' element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.CycleCount_Create]}><ScanCycleCount/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path="/cycle-count/:id/report" element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.CycleCount_View]}><ReportCycleCount/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path="/cycle-count/scan-adhoc" element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.AdhocCycleCount_Create]}><AdhocCycleCount/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path="/admin-panel" element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><AdminPanel/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path="/admin-panel/:id/tenant-settings" element={<AuthGuard><AdminLayout><PermissionGuard
                permissions={[permissions.Disaggregation]}><TenantSettings/></PermissionGuard></AdminLayout></AuthGuard>}/>


            <Route path='/reset-password'
                   element={<AuthGuard><AdminLayout><ResetPassword/></AdminLayout></AuthGuard>}/>
            <Route path='*' element={<Error/>}/>
        </>
    );
};

export default routes;
//     //Cycle Count
//     {
//         exact: true,
//         title: "Tenant Settings",
//         path: "/admin-panel/:id/tenant-settings",
//         //TODO
//         // permissions: [permissions.Disaggregation],
//         component: lazy(() => import("pages/admin-panel/TenantSettings/index.js")),
//     },
// ];
