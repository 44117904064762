//for pages with scanner feature.
export const isExist = (currentData, itemScanned) => {
  const item = currentData.find((e) => itemScanned.gtin === e.gtin);
  return item;
};
export const isScanned = (serials, codeScanned) => {
  return serials.some((s) => s === codeScanned);
};

export const mergeAdd = (arr1, arr2, id, key, code = "") => {
  let newArray = [...arr1];
  arr2.forEach((e) => {
    let item = isExist(arr1, e);
    if (item != null) {
      item[key] += e[key];
      if (code) item.code.push(code);
      newArray = newArray.filter((e) => e[id] !== item[id]);
      newArray.push(item);
    } else {
      newArray.push(e);
    }
  });
  return newArray;
};
export const isExistWithDifBatch = (currentData, itemScanned) => {
  const item = currentData.find(
    (e) => itemScanned.gtin === e.gtin && itemScanned.batch === e.batch
  );
  return item;
};

export const mergeAddWithDifBatch = (arr1, arr2, id, key, code = "") => {
  let newArray = [...arr1];
  arr2.forEach((e) => {
    let item = isExistWithDifBatch(arr1, e);
    if (item != null) {
      item[key] += e[key];
      if (code) item.code.push(code);

      newArray = newArray.filter((e) => {
        return (
          (e[id] === item[id] && e.batch !== item.batch) || e[id] !== item[id]
        );
      });
      newArray.push(item);
    } else {
      newArray.push(e);
    }
  });
  return newArray;
};

export const mergeRemove = (id, key, arr1 = [], arr2 = [], code = "") => {
  let oldTableData = [...arr1];
  arr2.forEach((e) => {
    let item = isExist(oldTableData, e);
    const newQuantity = item[key] - e[key];
    let index = oldTableData.findIndex((e) => e[id] === item[id]);

    if (newQuantity) {
      item[key] = newQuantity;
      oldTableData.splice(index, 1, item);
    } else {
      oldTableData.splice(index, 1);
    }
  });
  return oldTableData;
};
export const mergeRemoveWithDifBatch = (
  id,
  key,
  arr1 = [],
  arr2 = [],
  code = ""
) => {
  let oldTableData = [...arr1];
  arr2.forEach((e) => {
    let item = isExistWithDifBatch(oldTableData, e);
    if (code) item.code.push(code);
    const newQuantity = item[key] - e[key];
    let index = oldTableData.findIndex(
      (e) => e[id] === item[id] && e?.code[0] === item?.code[0]
    );

    if (newQuantity) {
      item[key] = newQuantity;
      oldTableData.splice(index, 1, item);
    } else {
      oldTableData.splice(index, 1);
    }
  });
  return oldTableData;
};

export const TitleJoin = (array, id) => {
  if (array.length >= 4) return `${array.length} Prodcuts`;
  return array.map((e) => e[id]).join(" - ");
};

export const validateQuantities = (linesQuantity, totalQuantity) => {
  let sum = linesQuantity.reduce((accumulator, object) => {
    return accumulator + parseInt(object.quantity);
  }, 0);

  let difference = parseInt(totalQuantity) - parseInt(sum);

  if (parseInt(totalQuantity) === parseInt(sum)) return false;
  else
    return `  The sum of lines quantities must match the order quantity
     (${
       difference > 0
         ? `${difference} Remaning`
         : `Exceeding by ${Math.abs(difference)}`
     })`;
};
export const trimObjectValues = (obj) => {
  const trimmedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      let trimmedValue;

      if (typeof value === "string") {
        trimmedValue = value.trim();
      } else if (typeof value === "object" && value !== null) {
        if (Array.isArray(value)) {
          trimmedValue = value.map((item) => {
            if (typeof item === "string") {
              return item.trim();
            } else if (typeof item === "object") {
              return trimObjectValues(item);
            } else {
              return item;
            }
          });
        } else {
          trimmedValue = trimObjectValues(value);
        }
      } else {
        trimmedValue = value;
      }

      trimmedObj[key] = trimmedValue;
    }
  }

  return trimmedObj;
};
