import permissions from "../config/permissions";

const fromJSON = (data) => ({
  id: data?.permissionId,
  name: data?.permissionName,
});

const getPermission = (p) => {
  return permissions.list.find((permission) => permission === p);
};

export const Permission = {
  fromJSON,
  getPermission,
};
