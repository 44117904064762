const fromSetting = ({ value, ...data }) => ({
  ...data,
  value:
    typeof value == "boolean"
      ? value
        ? "true"
        : "false"
      : value === "true" || value === "false"
      ? value == "true"
        ? true
        : false
      : value,
  isUpdateable: data?.isUpdateable,
});

export const TenantsData = {
  fromSetting,
};
