const fromJSON = (data) => {
  let result = {
    logo: data?.logo,
    permissions: data?.permissions,
    name: data?.tenantName || data?.name,
    id: data?.gln || data?.tenantId || data?.id,
  };

  result["label"] = result.name + " (" + result.id + ")";

  return result;
};

export const Tenant = {
  fromJSON,
};
