import {useSelector} from "react-redux";
import {MAIN_URL} from "../config/constants";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useNotificationAndBackdrop} from "../hooks/useNotificationAndBackdrop";
import {CircularProgress, Box} from "@mui/material";

const PermissionGuard = ({children, permissions}) => {
    const navigate = useNavigate()
    const {displayNotification} = useNotificationAndBackdrop();
    const [isLoading, setIsLoading] = useState(true)

    const userPermissions = useSelector(
        (state) => state?.auth?.user?.permissions ?? []
    );

    useEffect(() => {
        const pathname = window?.location?.pathname;
        if (!canAccessThisRoute() && pathname !== MAIN_URL) {
            navigate("/");
            displayNotification({
                content: `You need the following permissions [${permissions}]`,
                severity: "error",
            });
        } else {
            setIsLoading(false)
        }
    });

    // check if the user got this permission only if the route requires a permission
    const canAccessThisRoute = () => {
        if (permissions && permissions?.length > 0) {
            return permissions.every(permission => userPermissions.includes(permission));
        }
        return true;
    };

    return <> {isLoading ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
            <CircularProgress/>
        </Box>
    ) : (children)
    } </>;
};

export default PermissionGuard;
