import {useState} from "react";
import {Box} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {COMPANY_NAME} from "../config/constants";

const CustomImage = ({src, style, className, alt = ""}) => {
    const [imageError, setImageError] = useState(src == null);

    const onImageError = () => {
        setImageError(true);
    };

    return (
        <>
            {imageError ? (
                <Box
                    className={
                        "d-flex justify-content-center align-items-center " + className
                    }
                >
                    <Box
                        component='img'
                        alt={COMPANY_NAME}
                        style={style}
                        src={'/assets/pngs/origin-drawer-logo.png'}
                    />
                    {/*<ErrorOutlineIcon color="error" />*/}
                </Box>
            ) : (
                <img
                    src={src}
                    alt={alt}
                    className={className}
                    style={style}
                    onError={onImageError}
                />
            )}
        </>
    );
};

export default CustomImage;
