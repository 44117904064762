import { Permission } from "./Permission";
import { Tenant } from "./Tenant";
import { Role } from "./Role";

const fromJSON = (data) => {
  let result = {
    fullName: data?.fullName,
    ownerId: data?.ownerId,
    logo: data?.tenants[0]?.logo,
    permissions:
      data?.tenants[0]?.permissions
        ?.map((p) => Permission.getPermission(p))
        ?.filter((p) => p != null) ?? [],
    selectedTenant: Tenant.fromJSON(data?.tenants[0]),
  };

  result["tenants"] = data?.tenants?.map((e) => Tenant.fromJSON(e)) ?? [
    result.selectedTenant,
  ];

  return result;
};

const choosingTenant = (chosenTenant, fullName, tenants, ownerId) => {
  let result = {
    fullName,
    ownerId,
    id: chosenTenant?.id,
    logo: chosenTenant?.logo,
    // don't know why this logic is here
    // permissions:
    //   chosenTenant?.permissions
    //     ?.map((p) => Permission.getPermission(p))
    //     ?.filter((p) => p != null) ?? [],
    permissions: chosenTenant?.permissions,
    selectedTenant: Tenant.fromJSON(chosenTenant),
  };
  result["tenants"] = tenants?.map((e) => Tenant.fromJSON(e)) ?? [
    result.selectedTenant,
  ];
  return result;
};

const fromUsersJSON = (data) => ({
  fullName: data?.fullname,
  username: data?.userName,
  email: data?.email,
  password: data?.passWord,
  registerationDate: new Date(
    data?.registerationDate?.toString()
  )?.toDateString(),
  isActive: data?.isActive ? "Active" : "Inactive",
});


export const User = {
  fromJSON,
  fromUsersJSON,
  choosingTenant,
};
