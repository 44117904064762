import {Suspense} from "react";
import PermissionGuard from "gaurds/PermissionGuard";
import {RouterProvider, createBrowserRouter, createRoutesFromElements} from "react-router-dom";
import {Box, Fade} from "@mui/material";
import {COMPANY_NAME} from "../config/constants";
import routes from "../config/routes";

const Spinner = () => {
    return (
        <main
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >

            <Fade in={true} timeout={2000}>
                <Box
                    component='img'
                    alt={COMPANY_NAME}
                    sx={{
                        width: {xs: '20%'},
                    }}
                    src={'/assets/pngs/origin-original-logo.png'}
                />
            </Fade>
        </main>
    )
};

const RoutesBuilder = () => {
    const router = createBrowserRouter(
            createRoutesFromElements(
                routes()
            )
        )
    ;

    return (
        <Suspense fallback={<Spinner/>}>
            <RouterProvider router={router}/>
        </Suspense>
    );
};

export default RoutesBuilder;
